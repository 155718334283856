import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";

import frontImage from "images/face/faceproducts/daily-lotion.webp";
import frontCapImage from "images/face/faceproducts/daily-lotion-cap-off.webp";
import backImage from "images/face/faceproducts/daily-lotion-back.webp";
import frontZoomImage from "images/face/faceproducts/Daily Lotion_NEW.webp";
import frontCapZoomImage from "images/face/faceproducts/daily-lotion-cap-off.webp";
import backZoomImage from "images/face/faceproducts/daily-lotion-back.webp";

import img2 from "images/face/faceproducts/daily-lotion-chart.webp";

import img6 from "images/callouts/eucerin-face-product-family.webp";
import img7 from "images/callouts/cout-footer-daily-skin.webp";
//import img8 from "images/callouts/cout-footer-facecare.webp";
import img8 from "images/callouts/sunproducts2x.webp";
import "./dailylotion.scss";

/* function BoldText({children}) {
  return <span style={{fontWeight: 'bold'}}>{children}</span>;
} */

function EucerinFaceImmersiveHydrationDailyLotionPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      const myHash = window.location.hash.slice(1);
      const myScrollSpot = document.getElementById(myHash);
      if (myScrollSpot) {
        const currentBlock = myScrollSpot.getBoundingClientRect();
        window.scrollTo(0, currentBlock.top - 150);
      }
    }, 500);
  }, []);

  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts-age-defense page-eucerin-face-immersive-hydration-daily-lotion page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Face Immersive Hydration Daily Lotion SPF 30"
        pageDescription=""
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#106E9E"
              categoryName="FACE CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Face Immersive Hydration Daily Lotion SPF 30"
              productSlider={[frontImage, frontCapImage, backImage]}
              zoomImages={[frontZoomImage, frontCapZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Helps reduce the appearance of skin aging*
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Face Immersive Hydration Daily Lotion SPF 30
                  </span>{" "}
                  is formulated with{" "}
                  <span className="font-semi-bold">
                    <nobr>multi-weight</nobr> hyaluronic acid
                  </span>{" "}
                  to hydrate the skin’s surface at two levels and is enriched
                  with an{" "}
                  <span className="font-semi-bold">antioxidant complex</span> to
                  provide free radical defense.
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>Broad-spectrum SPF 30 sunscreen</span>
                  </li>
                  <li>
                    <span>Lightweight formulation</span>
                  </li>
                  <li>
                    <span>Works well under makeup</span>
                  </li>
                  <li>
                    <span>
                      Diminishes the appearance of fine lines and wrinkles
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Active ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Avobenzone 3%, Homosalate 10%, Octisalate 4.5%, Octocrylene
                  10% (sunscreens)
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>UVA/UVB broad-spectrum sun protection</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Multi-weight hyaluronic acid
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      Intense moisture hydrates deep within the surface layers
                      of the skin
                    </span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">Antioxidant complex</p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Soy-Derived Glycine-Saponin
                      </span>{" "}
                      is an effective, natural substance that helps scavenge
                      free radicals
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Glycyrrhetinic Acid,
                      </span>{" "}
                      derived from roots of Chinese licorice plants, helps
                      defend the skin against free radicals
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Vitamin E (Tocopherol)
                      </span>{" "}
                      helps guard against free radical formation
                    </span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold oceanicblue-text sub-title">
                    Formulated for all skin types
                  </p>
                  <ul className="checkmarks oceanicblue">
                    <li>
                      <span>NON-COMEDOGENIC</span>
                    </li>
                    <li>
                      <span>FRAGRANCE-FREE</span>
                    </li>
                  </ul>
                </div>
                <p className="references-symbols last">
                  *With continuous use after 2 weeks. Use as directed with other
                  sun protection measures (see Drug Facts Box).
                </p>
              </Col>
            </Row>
          </section>
        </div>

        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="CLINICAL RESULTS"
              productThemeColor="oceanic-blue"
              id="clinicalresults"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Bar graph showing clinical grading of improved hydration at week 2 using Daily lotion"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Eight-week
                    study to compare moisturization effects and changes in
                    facial skin quality following application of Immersive
                    Hydration Daily Lotion SFP 30. Females (n=35), aged 25 to
                    65, with mild to moderate facial dryness and visible fine
                    lines and wrinkles, applied product to the face once per day
                    in the morning. Clinical grading on face (dryness,
                    roughness, fine lines, and wrinkles) assessed at baseline,
                    Week 2, Week 4, and Week 8.
                  </p>
                  <p className="references-symbols last">
                    *Statistically significant compared to baseline (
                    <span className="font-italic">p&lt;</span>0.05).
                  </p>
                  <p className="references last">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Significant&nbsp;
                    <br className="show-desktop" />
                    improvements begin <br className="show-desktop" />
                    at Week 2<sup>1</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Eucerin face family product line"
                  caption="Find resources to support your face care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img8}
                  imgAlt="EXPLORE SUN CARE"
                  caption=" Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EucerinFaceImmersiveHydrationDailyLotionPage;
